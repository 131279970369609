<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container product---container">
      <page-loading :show="loading" />
      <v-tabs center-active v-model="activeTab">
        <v-tab :href="`#tab-account`" @click="changeItemType('account')" v-show="showMainPackages">
          {{ $t('product.account.title') }}
        </v-tab>
        <v-tab :href="`#tab-coin`" @click="changeItemType('coin')" v-show="showCoinPackages">
          {{ $t('product.coin.title') }}
        </v-tab>
        <v-tab
          :href="`#tab-listing-availability`"
          @click="changeItemType('listingAvailability')"
          v-show="showAvailabilityPackages"
        >
          {{ $t('product.listingAvailability.title') }}
        </v-tab>
        <v-tab
          :href="`#tab-upgrade`"
          @click="changeItemType('upgrade')"
          v-show="showUpgradePackages"
        >
          {{ $t('product.upgrade') }}
        </v-tab>
        <v-tab
          :href="`#tab-renewal`"
          @click="changeItemType('renewal')"
          v-show="showRenewalPackages"
        >
          {{ $t('product.renewal.title') }}
        </v-tab>
        <v-tab-item :value="`tab-account`">
          <div class="product--group" v-show="showMainPackages">
            <div class="row mt-2 align-items-stretch">
              <div
                class="col-12 col-md-4"
                v-for="product in allowedMainProducts"
                :key="product.uuid"
              >
                <card-account-product :product="product" :clickable="true"></card-account-product>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item :value="`tab-coin`">
          <div class="product--group" v-show="showCoinPackages">
            <div class="row mt-2 align-items-stretch">
              <div class="col-12 col-md-4" v-for="product in coinProducts" :key="product.uuid">
                <card-coin-product :product="product" :clickable="true"></card-coin-product>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item :value="`tab-listing-availability`">
          <div class="product--group" v-show="showAvailabilityPackages"></div>
        </v-tab-item>
        <v-tab-item :value="`tab-upgrade`">
          <div class="product--group" v-show="showUpgradePackages">
            <div class="row mt-2 align-items-stretch">
              <div class="col-12 col-md-4" v-for="product in upgradeProducts" :key="product.uuid">
                <card-account-product :product="product" :clickable="true"></card-account-product>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item :value="`tab-renewal`">
          <div class="product--group" v-show="showRenewalPackages">
            <div class="row mt-2 align-items-stretch">
              <div class="col-12 col-md-4" v-for="product in renewalProducts" :key="product.uuid">
                <card-account-product :product="product" :clickable="true"></card-account-product>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
      <div class="d-flex justify-center">
        <product-button :activeTab="activeTab"></product-button>
      </div>
    </div>
    <product-tos :activeTab="activeTab"></product-tos>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import CardAccountProduct from '@/components/product/card-account-product';
import CardCoinProduct from '@/components/product/card-coin-product';
import ProductButton from '@/components/product/product-button';
import ProductTos from '@/components/product/product-tos';
import PageLoading from '@/components/content-loading/page-loading';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    CardAccountProduct,
    CardCoinProduct,
    ProductButton,
    ProductTos,
    PageLoading,
  },
  data() {
    return {
      itemType: 'account',
      activeTab: 0,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.product.loading,
      coinProducts: (state) => state.product.coinProducts,
      upgradeProducts: (state) => state.product.upgradeProducts,
      allowUpgrade: (state) => state.product.allowUpgrade,
      allowListingAvailability: (state) => state.product.allowListingAvailability,
      listingAvailabilityProducts: (state) => state.product.listingAvailabilityProducts,
      renewalProducts: (state) => state.product.renewalProducts,
      allowRenewal: (state) => state.product.allowRenewal,
      user: (state) => state.global.user,
    }),
    ...mapGetters({
      allowedMainProducts: 'product/allowedMainProducts',
    }),
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.product');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
    showMainPackages() {
      return (
        (this.user === null || this.user.membership === null) &&
        !this.allowUpgrade &&
        this.allowedMainProducts.length > 0
      );
    },
    showCoinPackages() {
      return this.coinProducts.length > 0;
    },
    showAvailabilityPackages() {
      return (
        this.user !== null &&
        this.user.membership !== null &&
        this.allowListingAvailability &&
        this.listingAvailabilityProducts.length > 0
      );
    },
    showUpgradePackages() {
      return (
        this.user !== null &&
        this.user.membership !== null &&
        this.allowUpgrade &&
        this.upgradeProducts.length > 0
      );
    },
    showRenewalPackages() {
      return (
        this.user !== null &&
        this.user.membership !== null &&
        this.allowRenewal &&
        this.renewalProducts.length > 0
      );
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    activeTab(val) {
      this.handleListingAvailabilityTab();
    },
  },
  methods: {
    changeItemType(itemType) {
      this.itemType = itemType;
    },
    handleListingAvailabilityTab() {
      console.log('HANDLE LISTING AVAILABILITY TAB: ', this.activeTab);
      this.$store.commit('product/SET_RENEWAL_TYPE', null);
      this.$store.commit('product/SET_SELECTED_QTY', null);
      this.$store.commit('product/SET_SELECTED_DURATION', null);
      if (
        this.activeTab === 'tab-listing-availability' &&
        this.listingAvailabilityProducts &&
        this.listingAvailabilityProducts.length > 0
      ) {
        this.$store.commit('product/SET_SELECTED_PRODUCT', this.listingAvailabilityProducts[0]);
        this.$store.commit('product/SET_SELECTED_QTY', 20);
        this.$store.commit('product/SET_SELECTED_DURATION', {
          id: 1,
          name: '30 hari (~1 bulan)',
          value: 30,
        });
      } else {
        this.$store.commit('product/SET_SELECTED_PRODUCT', null);
      }
    },
  },
  async mounted() {
    try {
      this.$store.commit('checkout/SET_CHECKOUT_INFO', null);
      await this.$store.dispatch('product/initProductPage', {
        membership: this.user ? this.user.membership : null,
      });
    } catch (e) {
      console.log('ERROR LOAD PRODUCT PAGE: ', e);
    }

    if (this.$route.name === 'coin') {
      this.activeTab = 'tab-coin';
    } else if (this.$route.name === 'listing-availability') {
      this.activeTab = 'tab-listing-availability';
    } else if (this.$route.name === 'upgrade') {
      this.activeTab = 'tab-upgrade';
    } else if (this.$route.name === 'renewal') {
      this.activeTab = 'tab-renewal';
    } else {
      if (!this.showMainPackages) {
        this.activeTab = 'tab-coin';
      }
    }
  },
};
</script>
